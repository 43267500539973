import {router} from '@/router/index.js';
import axios from 'axios';
import store from "@/common/js/common/store/store.js"

// Add a request interceptor
axios.interceptors.request.use(function (config) {

    config.data.gvLoginId = store.getters.getUserId

    if (localStorage.getItem('accessToken') != null)
        config.headers.setAuthorization(localStorage.getItem('accessToken'));

    store.commit('setIsLoading', true);
    return config;
}, function (error) {
    // Do something with request error
    store.commit('setIsLoading', false);
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    setTimeout(() => {
        store.commit('setIsLoading', false);
    }, 250);

    if (response.data.ResultCode == 'Validation') {
        store.commit('popStateCode', 'error');
        store.commit('popStateMsg', response.data.ResultMsg);
        store.commit('popStateChange', true);
        throw new axios.Cancel('Validation Exception.');
    }

    if (response.data.loginCode == '9999') { // 로그인 만료
        console.log("로그인 만료")
        localStorage.removeItem('accessToken')
        router.push('/')
        return
    }

    return response;
}, function (error) {
    console.log(error)
    store.commit('setIsLoading', false);

    store.commit('popStateCode', 'error');
    store.commit('popStateMsg', "시스템 오류가 발생하였습니다.");
    store.commit('popStateChange', true);

    return Promise.reject(error);
});

export default axios;