<template>
  <findPassword v-if="this.openModal == true" @sendClose="closeModalView" />
  <changePassword
    v-if="this.openChangePw == true"
    @sendClose="closeChangePasswordModal"
    :loginId="changeTarget"
  />
  <PopupAlert />
  <div class="wrap">
    <!-- login S -->
    <div class="login">
      <div class="logo">
        <p><img src="../../common/images/bi-l.png" alt="" /></p>
        <span><i>MySuit</i>Magellan</span>
      </div>
      <!-- info-type S -->
      <ul class="info-type">
        <li>
          <p>아이디</p>
          <input
            class="w100p"
            type="text"
            ref="txt_user_id"
            v-model="postData.user_id"
            @keydown.enter="fn_onPressEnter"
          />
        </li>
        <li>
          <p>비밀번호</p>
          <input
            class="w100p"
            type="password"
            ref="txt_user_pw"
            v-model="postData.user_pw"
            @keydown.enter="fn_onPressEnter"
          />
        </li>
      </ul>
      <!--// info-type E -->
      <button type="button" class="btn-icon no-icon" @click="fn_login()">
        <span>로그인</span>
      </button>
      <div class="btn-sub">
        <button type="button" class="btn-line" @click="fn_menuMove('Join')">
          <span>회원가입</span>
        </button>
        <button type="button" class="btn-line" @click="openPop()">
          <span>비밀번호 찾기</span>
        </button>
      </div>
    </div>
    <!--// login E -->
  </div>
</template>

<script>
import findPassword from "@/views/popup/common/findPassWord.vue";
import changePassword from "@/views/popup/common/changePassWord.vue";
export default {
  data() {
    return {
      postData: {
        user_id: "",
        user_pw: "",
      },
      openModal: false,
      openChangePw: false,
      changeTarget: "",
      loginId: "",
      testData: {
        id: "admin",
        password: "1234",
        accessKey: "",
      },
    };
  },
  methods: {
    fn_menuMove(_pageName) {
      this.$router.push("/login/" + _pageName);
    },
    fn_login() {
      localStorage.removeItem("accessToken");

      this.axios.post("/login", this.postData).then((response) => {

        if(response != undefined){

          if (response.data.resultMap.user_pw_chk == "Y") {
            this.changeTarget = response.data.resultMap.user_id;
            this.openChangePasswordModal();
            this.postData.user_id = "";
            this.postData.user_pw = "";
          } else {
            localStorage.setItem(
              "accessToken",
              response.data.resultMap.accessToken
            );
  
            const auth = response.data.resultMap.adminYn;
            this.$store.commit("setUserAuth", auth);
  
            const masterYn = response.data.resultMap.masterYn;
            this.$store.commit("setUserMasterYn", masterYn);
  
            const userId = response.data.resultMap.user_id;
            this.$store.commit("setUserId", userId);
  
            const userNm = response.data.resultMap.user_nm;
            this.$store.commit("setUserNm", userNm);
  
            const compCd = response.data.resultMap.comp_cd;
            this.$store.commit("setCompCd", compCd);
  
            const compNm = response.data.resultMap.comp_nm;
            this.$store.commit("setCompNm", compNm);
  
            const envMysuitUrl = response.data.resultMap.envMysuitUrl;
            this.$store.commit("setMysuitUrl", envMysuitUrl);
  
            let pageName;
  
            if (auth === "Y") pageName = "SubscriptionApproval";
            else pageName = "CreateProject";
            
            this.$router.push({
              name: pageName,
              state: { auth: auth },
            });
          }
        }

      })
      .catch(function (error) {
        console.log(error)
      })
    },
    fn_onPressEnter() {
      if (this.postData.user_id !== "" && this.postData.user_pw !== "") {
        this.fn_login();
      } else if (this.postData.user_id === "") {
        this.$refs.txt_user_id.focus();
      } else if (this.postData.user_pw === "") {
        this.$refs.txt_user_pw.focus();
      }
    },
    openPop() {
      this.openModal = true;
    },
    closeModalView(data) {
      this.openModal = data;
    },
    openChangePasswordModal() {
      this.openChangePw = true;
    },
    closeChangePasswordModal(data) {
      this.openChangePw = data;
    },
    fn_test() {
      this.$router.push("/MainLayout");
    },
    fn_test2() {
      this.axios.post("/api/api-test", this.testData).then((response) => {
        console.log(response);
      });
    },
  },
  components: {
    findPassword,
    changePassword,
  },
  mounted() {},
};
</script>

<style scoped>
@import "../../common/css/import.css";
</style>
