<template>
<!-- pop-dim S -->
  <div v-if="this.$store.getters.getCommonPopState" class="pop-dim">
    <!-- pop_layer S -->
    <div class="pop-layer-s">
      <!-- pop_header S -->
      <div class="pop-header no-shadow">
        <button @click="cancel()">닫기버튼</button>
      </div>
      <!--// pop_header E -->
      <!-- pop_contents S -->
      <div class="pop-contents al-c pdt0 pdb20">
        <!--에러클래스:pop-MsgError/성공클래스:pop-MsgSuccess/확인클래스:pop-MsgComfirm/정보클래스:pop-MsgInfo-->
        <span :class="{ 'pop-MsgError': confirm.type == 'error', 'pop-MsgSuccess': confirm.type == 'success',
          'pop-MsgComfirm': confirm.type == 'confirm', 'pop-MsgInfo': confirm.type == 'info', 'pop-Msgimg': true}"></span>
        <p>{{ confirm.message }}</p>
      </div>
      <!--// pop_contents E -->
      <!-- pop_footer S -->

      <div class="pop-footer al-c">
        <button class="btn-icon no-icon" type="button" @click="done()"><span>확인</span></button>
        <button v-if="confirm.type == 'confirm'" class="btn-line" type="button" @click="cancel()"><span>취소</span></button>
      </div>
      <!--// pop_footer E -->
    </div>
    <!--// pop_layer E -->
  </div>
  <!--// pop-dim E -->
</template>

<script>
import store from '@/common/js/common/store/store';

export default {
  data() {
    return {
      confirm: this.$commonPop.sConfirmData
    };
  },
  methods: {
    done() {
      this.$store.commit('CommonPopState', false)
      this.confirm.done();
    },
    cancel() {
      this.$store.commit('CommonPopState', false)
      this.confirm.cancel();
    }
  },
  setup () {
    store.commit('CommonPopState', false)
  }
};
</script>