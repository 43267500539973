import { createApp } from 'vue';
import App from './App.vue';

import axios from '@/common/js/common/axios/axios.js';

import cmmn from '@/common/js/common/common.js';
import store from "@/common/js/common/store/store";
import { router } from '@/router/index.js';


import PopupAlert from '@/views/content/AlertSample.vue' // 수정 후 삭제
import SlideUpDown from 'vue-slide-up-down';
// 로딩바
import LoadingBar from '@/views/component/LoadingBar.vue'

// 공통팝업
import Confirm from '@/views/component/popup/commonPop.js';
import AxiosPop from '@/views/component/popup/AxiosPop.vue'

const app = createApp(App);

app.config.globalProperties.axios = axios;
app.config.globalProperties.$common = cmmn;
app.config.globalProperties.$store = store;



app.component('slide-up-down', SlideUpDown)
app.component('PopupAlert', PopupAlert) // 수정 후 삭제
app.component('LoadingBar', LoadingBar)
app.component('AxiosPop', AxiosPop)


app.use(router).use(store).use(Confirm).mount('#app')


