<template>
  <!-- pop-dim S -->
  <div class="pop-dim">
    <!-- pop_layer S -->
    <div class="pop-layer">
      <!-- pop_header S -->
      <div class="pop-header">
        <span class="title">임시 비밀번호 발급</span>
        <button @click="closeModal()">닫기버튼</button>
      </div>
      <!--// pop_header E -->
      <!-- pop_contents S -->
      <div class="pop-contents">
        <!-- info-type S-->
        <table>
          <colgroup>
            <col width="9%" />
            <col width="18%" />
            <col width="" />
          </colgroup>
          <caption>
            임시 비밀번호 발급
          </caption>
          <tbody>
            <tr>
              <td class="al-c"></td>
              <th>아이디</th>
              <td>
                <input
                  class="w250 al-l"
                  type="text"
                  v-model="this.postData.user_id"
                />
              </td>
            </tr>
            <tr>
              <td>
                <br />
              </td>
            </tr>
            <tr>
              <td class="al-c"></td>
              <th>메일 주소</th>
              <td>
                <input
                  class="w250 al-l"
                  type="text"
                  v-model="this.postData.user_email"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <!--// info-type E-->
      </div>
      <!--// pop_contents E -->
      <!-- pop_footer S -->
      <div class="pop-footer">
        <button
          type="button"
          class="btn-icon no-icon"
          @click="sendEmail()"
        >
          <span>발송</span>
        </button>
        <button type="button" class="btn-line" @click="closeModal()">
          <span>닫기</span>
        </button>
      </div>
      <!--// pop_footer E -->
    </div>
    <!--// pop_layer E -->
  </div>
  <!--// pop-dim E -->
</template>

<script>
export default {
  data() {
    return {
      postData: {
        user_id: "",
        user_email: "",
      },
    };
  },

  methods: {
    closeModal() {
      this.$emit("sendClose", false);
    },
    sendEmail() {
      this.axios
        .post("/email/sendMail", this.postData)
        .then(() => {
          this.$emit("sendClose", false);
          this.$commonPop.show({
              message: '임시 비밀번호가 메일로 전송되었습니다.',
              type: 'success', // success, confirm, error
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
