<template>
  <div class="app">
    <router-view></router-view>
    <LoadingBar/>
    <CommonPop/>
    <AxiosPop/>
  </div>
</template>

<script>
import store from "@/common/js/common/store/store";

export default {
  setup() {
    store.commit('setIsLoading', false);

    store.commit('popStateChange', false);
  },
}

</script>

<style></style>
