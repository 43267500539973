import CommonPop from '@/views/component/popup/CommonPop.vue';
import store from '@/common/js/common/store/store';

const _initConfirmData = {
    message: '',
    type: '',
    done() {},
    cancel() {}
};
const confirm = {
    sConfirmData: { state: false },
    show(dialogData = {}) {
        store.commit('CommonPopState',true)
        Object.assign(this.sConfirmData, {
            ..._initConfirmData,
            ...dialogData
        });
    },
    cancel() {
        //this.sConfirmData.state = false;
        //this.sConfirmData.cancel();
        /*setTimeout(() => {
            Object.assign(this.sConfirmData, _initConfirmData);
        }, 500);*/
    }
};

export default {
    install(Vue) {
        Vue.config.globalProperties.$commonPop = confirm;
        Vue.component('CommonPop', CommonPop);
    }
};

export { confirm };