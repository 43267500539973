<template>
  <Loading v-model:active="this.$store.getters.getIsLoading" />
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  components: {
    Loading,
  },

};
</script>





