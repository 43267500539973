<template>
  <form @submit.prevent="submitForm">
    <!-- <PopupAlert /> -->
    <!-- pop-dim S -->
    <div class="pop-dim">
      <!-- pop_layer S -->
      <div class="pop-layer">
        <!-- pop_header S -->
        <div class="pop-header">
          <span class="title">비밀번호 변경</span>
          <button @click="closeModal()">닫기버튼</button>
        </div>
        <!--// pop_header E -->
        <!-- pop_contents S -->
        <div class="pop-contents">
          <!-- info-type S-->
          <table>
            <colgroup>
              <col width="8%" />
              <col width="30%" />
              <col width="" />
            </colgroup>
            <caption>
              비밀번호 변경
            </caption>
            <tbody>
              <tr>
                <td class="al-c"></td>
                <th>기존 비밀번호</th>
                <td>
                  <input
                    class="w250 al-l"
                    type="password"
                    v-model="this.postData.user_pw"
                    ref="userOrgPwInput"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <br />
                </td>
              </tr>
              <tr>
                <td class="al-c"></td>
                <th>변경 비밀번호</th>
                <td>
                  <input
                    class="w250 al-l"
                    type="password"
                    v-model="postData.user_changePw"
                    placeholder="비밀번호"
                    maxlength="20"
                    @input="validateUserPw"
                    ref="userPwInput"
                  />
                </td>
              </tr>
              <tr>
                <td class="al-c"></td>
                <th></th>
                <td>
                  <p
                    v-if="!isUserPwValid"
                    class="input-error"
                    style="color: red"
                  >
                    비밀번호는 4자이상 20자 이내이여야 합니다.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <br />
                </td>
              </tr>
              <tr>
                <td class="al-c"></td>
                <th>변경 비밀번호 확인</th>
                <td>
                  <input
                    class="w250 al-l"
                    type="password"
                    v-model="postData.user_changePw_chk"
                    placeholder="비밀번호 확인"
                    maxlength="20"
                    @input="validatePwConfirm"
                    ref="PwConfirmInput"
                  />
                </td>
              </tr>
              <tr>
                <td class="al-c"></td>
                <th></th>
                <td>
                  <p
                    v-if="!isPwConfirmValid"
                    class="input-error"
                    style="color: red"
                  >
                    비밀번호가 일치하지 않습니다.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <!--// info-type E-->
        </div>
        <!--// pop_contents E -->
        <!-- pop_footer S -->
        <div class="pop-footer">
          <button type="submit" class="btn-icon no-icon">
            <span>변경</span>
          </button>
          <button type="button" class="btn-line" @click="closeModal()">
            <span>닫기</span>
          </button>
        </div>
        <!--// pop_footer E -->
      </div>
      <!--// pop_layer E -->
    </div>
    <!--// pop-dim E -->
  </form>
</template>

<script>
export default {
  data() {
    return {
      postData: {
        user_id: "",
        user_pw: "",
        user_changePw: "",
        user_changePw_chk: "",
      },
      isUserPwValid: true,
      isPwConfirmValid: true,
      isChangePwValid : true,
    };
  },
  props: ["loginId"],
  methods: {
    closeModal() {
      this.$emit("sendClose", false);
    },
    openAlert() {
      this.$store.commit("popStateChange", true);
    },
    // 변경 비밀번호, 비밀번호 확인
    validateUserPw() {
      this.isUserPwValid = /^[A-Za-z0-9]{4,20}$/.test(
        this.postData.user_changePw
      );
    },
    validatePwConfirm() {
      this.isPwConfirmValid =
        this.postData.user_changePw === this.postData.user_changePw_chk;
    },
    validateChangePw(){
      this.isChangePwValid = this.postData.user_pw !== this.postData.user_changePw;
    },
    submitForm() {
      this.validateUserPw();
      this.validatePwConfirm();
      this.validateChangePw();

      if (this.isUserPwValid && this.isPwConfirmValid && this.isChangePwValid) {
        this.changePassWord();
      } else {
        var msg = '';
        var targetRef = '';
        if (!this.isUserPwValid) {
          msg = "비밀번호 형식이 일치하지 않습니다.";
          targetRef = this.$refs.userPwInput;

        } else if (!this.isPwConfirmValid) {
          msg = "비밀번호가 일치하지 않습니다.";
          targetRef = this.$refs.PwConfirmInput;

        } else if (!this.isChangePwValid) {
          msg = "기존 비밀번호와 변경할 비밀번호가 동일합니다.";
          targetRef = this.$refs.userOrgPwInput;
        }
        this.$commonPop.show({
            message: msg,
            type: 'error', // success, confirm, error
            done: () => {
              targetRef.focus();
            }
        });
      }
    },
    changePassWord() {
      this.postData.user_id = this.loginId;
      this.axios
        .post("/main/changePassWord", this.postData)
        .then(() => {
          this.$emit("sendClose", false);
          this.$commonPop.show({
              message: '비밀번호가 정상적으로 변경되었습니다.',
              type: 'success', // success, confirm, error
          });
          this.$router.push("/");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  components: {
  },
};
</script>
