import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
//import userStore from './userStore'

const store = createStore({
    state : {
      popState : false,
      popConfirm : false,
      stateCode : "",
      stateMsg : "",
      userAuth : "",
      userMasterYn : "",
      userId   : "",
      userNm   : "",
      compCd   : "",
      compNm   : "",
      slideState : false,
      isLoading : false,
      envMysuitUrl : "",
      // userStore: userStore,
      commonPopState : false,
    },
    
    getters : {
      getCommonPopState: function(state) {
        return state.commonPopState;
      },
      getPopState: function(state) {
        return state.popState;
      },
      getPopConfirm: function(state) {
        return state.popConfirm;
      },
      getPopStateCode: function(state) {
        return state.stateCode;
      },
      getPopStateMsg: function(state) {
        return state.stateMsg;
      },
      getUserAuth: function(state) {
        return state.userAuth;
      },
      getUserMasterYn: function(state) {
        return state.userMasterYn;
      },
      getUserId: function(state) {
        return state.userId;
      },
      getUserNm: function(state) {
        return state.userNm;
      },
      getCompCd: function(state) {
        return state.compCd;
      },
      getCompNm: function(state) {
        return state.compNm;
      },
      getSlideState: function(state) {
        return state.slideState;
      },
      getIsLoading: function(state) {
        return state.isLoading;
      },
      getMysuitUrl: function(state) {
        return state.envMysuitUrl;
      },
    },
    mutations : {
      CommonPopState : function(state, value){
          state.commonPopState = value;
      },
      // modal 창 상태여부
      popStateChange : function(state, value){
          state.popState = value;
      },
      // modal 창 confilm 여부
      popConfirm : function(state, value){
        state.popConfirm = value;
      },
      // modal 창 코드(success, error, info)
      popStateCode : function(state, value){
          state.stateCode = value;
      },
      // modal 창 메세지
      popStateMsg : function(state, value){
          state.stateMsg = value;
      },
      // 관리자여부
      setUserAuth : function(state, value){
          state.userAuth = value;
      },
      // 마스터여부
      setUserMasterYn : function(state, value){
        state.userMasterYn = value;
      },
      // 로그인 한 유저 아이디
      setUserId : function(state, value){
        state.userId = value;
      },
      // 로그인 한 유저명
      setUserNm : function(state, value){
        state.userNm = value;
      },
      // 로그인 한 유저 회사정보
      setCompCd : function(state, value){
        state.compCd = value;
      },
      // 로그인 한 유저 회사명
      setCompNm : function(state, value){
        state.compNm = value;
      },
      // 슬라이드 메뉴 on, off
      setSlideState : function(state, value){
          state.slideState = value;
      },
      setIsLoading : function(state, value){
        state.isLoading = value;
      },
      setMysuitUrl: function(state, value) {
        state.envMysuitUrl = value;
      },
    },
    actions : {
      
    },
    // plugins: [createPersistedState({
    //   paths: ["userStore"]
    // })]
    plugins: [
      createPersistedState()
    ]

  })

export default store;