<template>
<!-- pop-dim S -->
<div class="pop-dim" v-if="this.$store.getters.getPopState">
    <!-- pop_layer S -->
    <div class="pop-layer-s">
        <!-- pop_header S -->
        <div class="pop-header no-shadow">
            <button @click="closeAlert()">닫기버튼</button>
        </div>
        <!--// pop_header E -->
        <!-- pop_contents S -->
        <div class="pop-contents al-c pdt0 pdb20">
            <!--에러클래스:pop-MsgError/성공클래스:pop-MsgSuccess/확인클래스:pop-MsgComfirm/정보클래스:pop-MsgInfo-->  
            <!-- <span class="pop-Msgimg pop-MsgError">에러</span>  -->
            <span :class="{ 'pop-MsgError': this.$store.getters.getPopStateCode == 'error', 'pop-MsgSuccess': this.$store.getters.getPopStateCode == 'success',
            'pop-MsgComfirm': this.$store.getters.getPopStateCode == 'comfirm', 'pop-MsgInfo': this.$store.getters.getPopStateCode == 'info', 'pop-Msgimg': true}"></span> 
            
            <p>{{this.$store.getters.getPopStateMsg}}</p>
        </div>
        <!--// pop_contents E -->
        <!-- pop_footer S -->
        <div class="pop-footer al-c" v-if="this.$store.getters.getPopConfirm != true">
            <button type="button" class="btn-icon no-icon" @click="closeAlert()"><span>확인</span></button>
        </div>
        <!--// pop_footer E -->
    </div>
    <!--// pop_layer E -->
</div>
<!--// pop-dim E -->
</template>

<script>

import store from "@/common/js/common/store/store";

export default {
  data() {
    return {}
  },
  methods: {
    closeAlert() {
      this.$store.commit('popConfirm', false);
      this.$store.commit('popStateChange', false);
    },
  },
  setup() {
    store.commit('CommonPopState', false)
  }
}
</script>